<template>
  <div id="homeIndex">
    <keep-alive>
      <router-view />
    </keep-alive>

    <mt-tabbar v-model="activeTab" style="position: fixed">
      <mt-tab-item
        v-for="item in tabData"
        :key="item.id"
        :id="item.id"
        @click.native="clickTab(item)"
      >
        <img slot="icon" :src="require(`../assets/images/tab/${activeTab === item.id ? item.icon_active : item.icon}`)">
        {{ item.name }}
      </mt-tab-item>
    </mt-tabbar>
  </div>
</template>

<script>
// import { } from 'vuex'
export default {
  name: 'HomeIndex',
  components: {},
  props: [],
  data() {
    return {
      activeTab: 'home',
      tabData: [
        { id: 'home', name: '工单列表', icon: 'home.png', icon_active: 'home_active.png' },
        { id: 'knowledge', name: '知识库', icon: 'knowledge.png', icon_active: 'knowledge_active.png' },
        { id: 'personal', name: '个人中心', icon: 'personal.png', icon_active: 'personal_active.png' }
      ]
    }
  },
  computed: {},
  watch: {
    // activeTab() {
    //   this.$router.push({ name: this.activeTab })
    // },
    $route: {
      handler() {
        this.activeTab = this.$route.name
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    clickTab(item) {
      this.$router.push({ name: item.id })
    }
  }

}
</script>

<style lang="less" scoped>
#homeIndex{
  padding-bottom: 1rem;
}
</style>
